.desktop_navDropdownContent__L6fCl {
  animation-duration: 250ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.desktop_navDropdownContent__L6fCl[data-motion='from-start'] {
  animation-name: desktop_enterFromLeft__FaQkD;
}
.desktop_navDropdownContent__L6fCl[data-motion='from-end'] {
  animation-name: desktop_enterFromRight__2EHuM;
}
.desktop_navDropdownContent__L6fCl[data-motion='to-start'] {
  animation-name: desktop_exitToLeft__Qm9NP;
  display: none;
}
.desktop_navDropdownContent__L6fCl[data-motion='to-end'] {
  animation-name: desktop_exitToRight__SH_Fg;
  display: none;
}

.desktop_navViewport__DC1YG {
  height: var(--radix-navigation-menu-viewport-height);
  transform-origin: top center;
  transition: width 300ms ease, height, 300ms ease;
  overflow: hidden;
}

.desktop_navViewport__DC1YG[data-state='open'] {
  animation: desktop_enterIntoNav__eaUTG 500ms ease;
}
.desktop_navViewport__DC1YG[data-state='closed'] {
  animation: desktop_exitFromNav__JTuJf 200ms ease;
}

.desktop_fadeIn___Odlq {
  animation: desktop_fadeIn___Odlq 1000ms ease forwards;
}

@keyframes desktop_fadeIn___Odlq {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.desktop_card__qsuug {
  border-color: transparent;
}

.desktop_card__qsuug:hover {
  border-color: var(--dynamic-color);
}

/* keyframes */
@keyframes desktop_enterIntoNav__eaUTG {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes desktop_exitFromNav__JTuJf {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes desktop_enterFromLeft__FaQkD {
  from {
    transform: translateX(-25px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes desktop_enterFromRight__2EHuM {
  from {
    transform: translateX(25px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes desktop_exitToLeft__Qm9NP {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-25px);
    opacity: 0;
  }
}

@keyframes desktop_exitToRight__SH_Fg {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(25px);
    opacity: 0;
  }
}

.__icon__DvM_W {
  transition: all 300ms linear;
}

.__icon__DvM_W.active {
  transform: rotate(-135deg);
  transition: all 300ms linear;
}

.__accordion__juzyv .accordion__content {
  max-height: 0;
  transition: all 0.3s linear;
  overflow: hidden;
}

.__accordion__juzyv .accordion__content--expanded {
  transition: all 0.3s linear;
}

.mobile_hamburger__vITWI {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px 0;
}

.mobile_hamburger__vITWI > span {
  background: white;
  display: block;
  width: 1.5rem;
  height: 2px;
  border-radius: 8px;
  transition: transform 300ms ease;
}

.mobile_hamburger__vITWI > span:nth-child(1) {
  margin-bottom: 5px;
}

.mobile_hamburger__vITWI > span:nth-child(2) {
  margin-bottom: 5px;
}

.mobile_hamburger__vITWI.active > span:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.mobile_hamburger__vITWI.active > span:nth-child(2) {
  visibility: hidden;
}

.mobile_hamburger__vITWI.active > span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

.mobile_mobileNavDropdown__1CzIQ {
  --nav-header-height: 4.5rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: var(--nav-header-height);
  height: calc(var(--vh, 1vh) * 100 - var(--nav-header-height));
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out, height 500ms ease;
}

.mobile_mobileNavDropdown__1CzIQ.active {
  transform: translateX(0px);
}

.mobile_mobileNavDropdown__1CzIQ .mobile_menuItem__IyF5w,
.mobile_mobileNavDropdown__1CzIQ .accordion__header {
  position: relative;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 34px;
  padding: 16px 24px;
}

.mobile_mobileNavDropdown__1CzIQ .mobile_menuItem__IyF5w::before,
.mobile_mobileNavDropdown__1CzIQ .accordion__header::before {
  content: '';
  background: #ffffff1a;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
}

.mobile_mobileNavDropdown__1CzIQ .mobile_menuItem__IyF5w {
  color: var(--yellow-40);
}

.mobile_mobileNavDropdown__1CzIQ .accordion__header.active::after {
  background: none;
}

.mobile_mobileNavDropdown__1CzIQ .accordion--lg .accordion__content--expanded {
  max-height: -moz-max-content;
  max-height: max-content;
}

.mobile_mobileNavDropdown__1CzIQ .accordion__icon {
  color: white;
  margin: 0 1rem;
}

.mobile_mobileNavDropdown__1CzIQ .accordion__icon.active {
  rotate: 315deg;
}

.mobile_mobileNavDropdown__1CzIQ .accordion__header.active .mobile_mobileNavTitle___hhxh {
  color: white;
  font-weight: 600;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_ce1a57';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_ce1a57';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_ce1a57 {font-family: '__Inter_ce1a57', '__Inter_Fallback_ce1a57';font-style: normal
}.__variable_ce1a57 {--font-inter: '__Inter_ce1a57', '__Inter_Fallback_ce1a57'
}

@font-face {
font-family: '__interLocal_66fe52';
src: url(/_next/static/media/11d5bc9f0cad36d1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__interLocal_66fe52';
src: url(/_next/static/media/cac2ba46e8c8adc9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__interLocal_66fe52';
src: url(/_next/static/media/242d04bef81519ae-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__interLocal_66fe52';
src: url(/_next/static/media/d080ae18fd04e52c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__interLocal_Fallback_66fe52';src: local("Arial");ascent-override: 89.79%;descent-override: 22.36%;line-gap-override: 0.00%;size-adjust: 107.89%
}.__className_66fe52 {font-family: '__interLocal_66fe52', '__interLocal_Fallback_66fe52'
}.__variable_66fe52 {--font-inter-local: '__interLocal_66fe52', '__interLocal_Fallback_66fe52'
}

/* default style */
.__button__Lt6ZG {
  transition: color 300ms ease-in, background-color 300ms ease-in;
}

.__rounded__FtSyw {
  border-radius: 9999px;
}

/* button size */
.__small__iFt6k {
  min-height: 40px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.__standard__wAJgL {
  min-height: 48px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.__large__LCAHh {
  min-height: 56px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* button type */
.__primary__nkixA {
  background: var(--blue-50);
  color: var(--white);
}

.__primary__nkixA:hover {
  background: var(--blue-70);
}

.__secondary__svK_8 {
  background: var(--blue-10);
  color: var(--blue-50);
}

.__secondary__svK_8:hover {
  background: var(--blue-50);
  color: var(--white);
}

.__tertiary__8gm2N {
  background: var(--yellow-50);
  color: var(--gray-90);
}

.__tertiary__8gm2N:hover {
  background: var(--yellow-60);
}

.__pill__A8aQU {
  background: transparent;
  color: var(--gray-90);
  font-size: 13px;
  height: 26px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
}

.__pill__A8aQU:hover {
  background: var(--gray-10);
}

.__pill__A8aQU.active {
  background: var(--gray-90);
  color: var(--white);
}

.__orangePill__LJ1_W {
  color: var(--orange-50);
  padding-left: 16px;
  padding-right: 16px;
  min-height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

.__orangePill__LJ1_W:hover {
  background: var(--orange-10);
}

.__orangePill__LJ1_W.active {
  background: var(--orange-50);
  color: var(--white);
}

.__yellowPill__TxqFw {
  color: var(--yellow-30);
  border: 1px solid var(--yellow-30);
  padding-left: 44px;
  padding-right: 44px;
  min-height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

.__yellowPill__TxqFw:hover {
  background: var(--yellow-30);
  color: var(--blue-web-50);
}

.__yellowPill__TxqFw.active {
  background: var(--yellow-30);
  color: var(--blue-web-50);
}

/* button disabled */
.__button__Lt6ZG:disabled {
  cursor: not-allowed;
  background: var(--gray-40);
  color: var(--gray-10);
}

.__button__Lt6ZG:disabled:hover {
  background: var(--gray-40);
  color: var(--gray-10);
}

/* loading prop */
.__loader__WjFTb {
  animation: __loading__eWU1p 1.4s infinite linear;
  transform: translateZ(0);
}

@keyframes __loading__eWU1p {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

